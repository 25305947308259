// 控制台首页
export default ({ request }) => ({
    printHome() {
        return request({
            url: '/dhk/summary/portalInfo',
            method: 'get',
        });
    },
    /**
     * 获取试印校色统计
     * @param { Object } data
     */

    TimeDailyInfo(data) {
        return request({
            url: "/newApp/info/dayStatistics",
            method: "get",
            params: data,
        });
    },
    deviceInfo() {
        return request({
            url: "/newApp/deviceInfo/byTeam",
            method: "get",
        });
    }
});
