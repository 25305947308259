const zh = {
  login_select_avatar: '选择头像',
  login_login: '登录',
  login_regist: '注册',
  login_company_regist: '企业注册',
  login_personal_regist: '个人注册',
  login_forget_password: '忘记密码？',
  login_company_name: '企业名称',
  login_phone: '手机号',
  login_username: '用户名',
  login_nick_name: '昵称',
  login_password: '密码',
  login_email: '邮箱',
  login_verification_code: '验证码',
  login_now: '已有账号？马上登录',
  login_team_regist: '企业注册',
  login_password_again: '再次输入密码',
  login_submit: '提交',
  login_back: '返回登录',
  login_invalid_phone: '请输入正确的手机号',
  login_no_name: '请填写2-16位昵称',
  login_invalid_password: '请输入6-16位密码，支持数字字母下划线',
  login_invalid_email: '请输入正确的邮箱',
  login_no_verification_code: '请填写6位数字验证码',
  login_passwords_differ: '两次密码输入不一致',
  login_get_code: '获取验证码',
  login_get_code_later: 's 后获取',
  user_info_avatar: '头像',
  user_info_edit_avatar: '编辑头像',
  user_info_phone: '原手机号',
  user_info_email: '邮箱',
  user_info_nickname: '昵称',
  user_info_gender: '性别',
  user_info_secret: '保密',
  user_info_man: '男',
  user_info_woman: '女',
  user_info_submit: '提交',
  user_info_check_phone: '验证原手机号',
  user_info_modify_phone: '修改手机号',
  user_info_modify_password: '修改密码',
  user_info_code: '验证码',
  user_info_confirm: '确定',
  user_info_old_password: '原密码',
  user_info_new_password: '新密码',
  user_info_password_again: '再次输入密码',
  team_user_add_user: '添加企业成员',
  team_user_manage: '人员管理',
  team_user_no: '序号',
  team_user_nickname: '昵称',
  team_user_gender: '性别',
  team_user_role: '角色',
  team_user_phone: '手机号',
  team_user_email: '邮箱',
  team_user_department: '部门',
  team_user_regist_time: '注册时间',
  team_user_operation: '操作',
  team_user_edit: '编辑',
  team_user_delete: '删除',
  team_user_invite: '邀请用户',
  team_user_confirm: '确认',
  team_user_invite_prompt: '请输入被邀请人的手机号',
  team_user_role_select: '请选择角色',
  team_user_delete_user_prompt: '确认删除成员, 是否继续?',
  team_user_prompt: '提示',
  team_user_cancel: '取消',
  team_info: '团队信息',
  role_add: '添加角色',
  role_no: '序号',
  role_name: '角色名',
  role_manage: '角色管理',
  create_time: '创建时间',
  role_operation: '操作',
  role_modify_rolename: '修改角色名',
  role_view_auth: '查看权限',
  role_delete_role: '删除角色',
  role_enter_rolename: '请输入角色名',
  confirm: '确定',
  role_modify_role: '修改角色',
  role_back: '返回',
  role_menu: '菜单',
  role_auth: '权限',
  role_name_need: '角色名不能为空',
  team_name: '企业名称',
  team_address: '企业地址',
  team_province: '省',
  team_city: '市',
  team_area: '区',
  team_address_detail: '详细地址',
  team_email: '企业邮箱',
  team_profile: '企业介绍',
  team_submit: '提交',
  team_name_invalid: '团队名称无效',
  menu_user_info: '个人信息',
  ali_pay: '支付宝支付',
  pay_success: '支付成功',
  device_check_device_name: '设备名称',
  device_print_type_pre_press: '印前设备',
  device_print_type_print: '印刷设备',
  device_print_type_post_press: '印后设备',
  device_check_recent_task_list: '近期任务列表',
  device_check_overdue_task_list: '逾期任务列表',
  device_operation: '操作',
  device_print_add_maintain: '添加',
  device_print_view_check_list: '查看点检列表',
  device_maintain_template: '选择模板',
  device_select: '请选择',
  device_maintain_name: '保养单单号',
  device_maintain_remark: '保养备注',
  please_device_maintain_remark: '请填写保养备注',
  device_check_remark: '点检备注',
  please_device_check_remark: '请填写点检备注',
  device_maintainer: '保养人员',
  device_select_time: '选择时间',
  device_start_time: '开始时间',
  device_end_time: '结束时间',
  device_maintain_add: '添加保养内容',
  device_save: '保存',
  device_no: '序号',
  device_part: '部位',
  device_element: '组件',
  device_work: '工作',
  device_tool: '工具仪器',
  device_standard: '保养标准',
  device_edit: '编辑',
  device_delete: '删除',
  device_add: '添加',
  device_back: '返回',
  device_remark: '备注',
  device_check_finish: '点检完成',
  device_status: '是否异常',
  device_finish: '是否完成',
  device_maintain_score: '评分',
  device_confirm: '确认',
  device_maintain_release: '解除',
  device_maintain_abnormal_confirm: '异常确认',
  device_fault_type: '故障类型',
  device_repair_desc: '故障描述',
  device_enter: '请输入内容',
  device_repair_pic: '故障图片',
  device_state: '状态',
  device_view: '查看',
  device_maintain_to_maintain: '待保养',
  device_maintain_to_check: '待点检',
  device_maintain_checked: '点检完成',
  device_add_name: '设备名称',
  device_add_enter_name: '请填写自定义名称',
  serial_number: '机身编号',
  device_add_enter_code: '请填写机身编号',
  device_repair_no: '报修单号',
  device_enter_type: '请选择设备类型',
  device_production_date: '出厂日期',
  device_select_date: '选择日期',
  device_config: '配置',
  device_select_config: '请选择配置',
  device_install_location: '安装位置',
  device_enter_location: '请填写安装位置',
  device_enter_remark: '请填写备注',
  device_modify: '修改',
  device_print_type: '印刷类型',
  device_brand: '品牌',
  device_series: '系列',
  device_model: '型号',
  device_delete_prompt: '确认删除设备, 是否继续?',
  device_prompt: '提示',
  device_cancel: '取消',
  device_commit: '提交',
  device_prompt_finish_all_maintain: '请完成所有保养项才可以提交',
  device_halfyear: '半年度',
  device_quarter: '季度',
  device_month: '月度',
  device_week: '周',
  device_day: '日',
  device_calendar_mode: '日历模式',
  device_list_mode: '列表模式',
  device_prompt_delete_maintain: '确认删除保养单据, 是否继续?',
  device_create_bill: '创建单据',
  device_maintain_time: '报修时间',
  device_maintain_person: '报修人',
  device_repair_person: '维修人',
  device_contact: '联系方式',
  device_prompt_select: '请选择设备',
  device_prompt_repair_type: '请填写故障类型',
  device_prompt_repairman: '请选择维修人员',
  device_prompt_repair_desc: '请填写故障类型',
  device_prompt_confirm_delete: '是否确认删除?',
  device_prompt_upload_number: '最多上传3张图片',
  device_repair_info: '报修信息',
  no: '无',
  device_repair_record: '维修记录',
  device_repair_process: '维修方式',
  device_repair_time: '维修时间',
  finish: '已完成',
  unfinish: '未完成',
  device_prompt_modify_commit: '修改即将提交, 是否继续?',
  device_template_item: '模板明细',
  device_template_name: '模板名称',
  not_null: '不能为空',
  device_prompt_item_add: '请添加明细',
  // template: '模板',
  // template_type: '模板类型',
  // copy: '复制',
  template_system: '系统模板',
  template_personal: '个人模板',
  prompt_select_avatar: '请选择一张图片',
  prompt_pic_upload: '请选择图片上传',
  save_success: '保存成功',
  abnormal: '异常',
  confirm_abnormal: '确认异常',
  release_abnormal: '解除异常',
  prompt_one_star: '评分最小为1星',
  prompt_release_abnormal: '确认解除异常，是否继续？',
  prompt_handel_abnormal: '请处理异常',
  prompt_repair_type_error: '故障类型不超过20个字',
  prompt_repair_desc_error: '故障描述不超过100个字',
  no_more_than_500: '500字以内',
  check_team_name: '请输入2-50个字符的公司名',
  check_team_detail_address: '详细地址不超过50字',
  check_email: '邮箱不合法',
  check_team_profile: '公司介绍不超过500字',
  login_passwords_nomodify: '原密码和新密码一致，无需修改',
  prompt_maintain_type_error: '维修方式不超过20个字',
  prompt_select_time: '请选择时间',
  prompt_select_type: '请选择状态',
  check_template_name: '请输入2-50个字符的模板名',
  check_device_part: '请输入2-50个字符的部位信息',
  check_device_element: '请输入2-50个字符的组件信息',
  check_device_work: '请输入2-50个字符的工作信息',
  check_device_sop: '请输入2-200个字符的SOP信息',
  check_device_tool: '工具信息不超过50字',
  check_device_standard: '请输入2-200个字符的标准信息',
  prompt_delete_detail: '确定删除此条明细吗?',
  check_select_maintainer: '请选择保养人员',
  check_maintian_name: '请填写2-50个字符的保养单名称',
  check_select_start_time: '请选择开始时间',
  check_select_end_time: '请选择结束时间',
  check_start_more_than_end: '结束时间要大于开始时间',
  check_add_miantian_detail: '请添加保养内容',
  device_check: '设备点检',
  device_list: '设备列表',
  device_repair: '设备维修',
  add_device: '新增设备',
  device_detail: '设备明细',
  device_fault_record: '故障记录',
  personal_settings: '个人设置',
  home: '主页',
  mryinliangfenxi: '每日印量分析',
  zongyinzhang: '总印张',
  timejiadonglv: '时间稼动率',
  xingnengjiadonglv: '性能稼动率',
  shuimopinghengReport: '水墨平衡报告',
  cepingdevice: '已评测设备数',
  runbanjiankong: '润版液监控',
  ysgjshujuReport: '印刷管家数据报告',
  yinshua: '印刷',
  zhiliangReport: '质量报告',
  zhiban: '制版',
  weibaopingbi: '维保评比',
  weixiuReport: '维修报告',
  banbengongneng: '标准版、专业版及专业加强版特有功能',
  shengji: '请先升级!',
  queding: '确定',
  zongyinzhangtongji: '总印张统计',
  day7: '近7天',
  day15: '近15天',
  day30: '近30天',
  months2: '近两月',
  noData: '暂无数据',
  zhang: '张',
  year: '年',
  alldevice: '全部设备',
  timejiadonglvReport: '时间稼动率日报表',
  pingjuntime: '当日平均时间稼动率',
  timeguanxi: '当日平均时间稼动率代表了今日机器工作时间与开机时间额定运行的时间关系',
  timeTongji: '时间稼动率统计',
  kaijiTime: '开机时长',
  min: '分钟',
  youxiaoTime: '有效时间',
  deviceName: '设备名称',
  yichangreasontongji: '异常待机原因统计',
  yinliangTime: '试印刷印量及时间',
  cishu: '次数',
  timeHour: '时长',
  yinliang: '印量',
  caozuo: '操作',
  checkDetail: '查看明细',
  xuanzeTime: '请选择年月',
  allClass: '全部班组',
  device: '设备',
  orderIndex: '订单序号',
  edingTime: '额定待机时长',
  yichangTime: '异常待机时长',
  yichangReason: '异常待机原因',
  shiyinzhanbi: '试印校色占比',
  deviceTimeTongji: '设备时间稼动率统计',
  xingnengjiadonglvReport: '性能稼动率日报表',
  pingjunjiadonglv: '当日平均性能稼动率',
  timeBizhiguanxi: '当日平均性能稼动率代表了已印数量和运行时长的比值与稳定产能和额定工时的比值的比值关系',
  deviceTongji: '设备统计',
  classtongji: '班次统计',
  orderJiadonglv: '点击设备对应的印单可查看该单的性能稼动率',
  report: '报表',
  edingchanneng: '额定产能',
  shijichanneng: '实际产能',
  xuanzheDate: '请选择日期',
  devicexingnengTongji: '设备性能稼动率统计',
  index: '序号',
  startTime: '开始时间',
  endTime: '结束时间',
  zongyinzhangshu: '总印张(张)',
  youxiaoyinzhang: '有效印张(张)',
  wuxiaoyinzhang: '无效印张(张)',
  guobanyinzhang: '过版印张(张)',
  shuimopinghengaStateReport: '水墨平衡状态报表',
  cepingdeviceshu: '评测设备数',
  you: '优',
  liang: '良',
  zhong: '中',
  cha: '差',
  cepingTime: '评测时间',
  mostRecentceping: '最近一次评测结果',
  shuizhijiance: '水质监测',
  daybaojing: '当日报警',
  monthsBaojing: '本月报警',
  chaobiaoTime: '超标问题处理时间统计',
  chaobiaoTimetongji: '最近7次所有设备润版液超标问题处理时间统计',
  chaobiaoreason: '超标原因统计',
  runbanDevice: '最近7天所有设备每天润版液超标原因统计',
  PHvalue: 'PH值',
  Water: '水温',
  diandaolv: '电导率',
  di: '第',
  ci: '次',
  // '第--次',
  total: '总数',
  dayReport: '日报',
  weekReport: '周报',
  monthsReport: '月报',
  xuanze: '请选择',
  xuanzemonths: '选择一个或多个月',
  dataDay: '数据日期',
  company: '印工社（青岛）数字科技有限公司',
  yinshuanumber: '印刷数量',
  unitzhang: '单位:张',
  numberliyonglv: '实际印刷数量/产能利用率',
  xiaolvguanli: '效率管理',
  biaozhunyinliang: '标准印量(张)',
  shijiyinliang: '实际印量(张)',
  shujumiaoshu: '数据描述',
  unitTime: '单位:印张/小时',
  sudujiadonglv: '实际运行速度 / 性能稼动率',
  edingyunxingsudu: '额定运行速度',
  unitmin: '单位:分钟/天',
  unithour: '单位:小时/天',
  hourjiadonglv: '有效印刷时长 / 时间稼动率',
  youxiaohour: '有效印刷时长(分钟/小时)',
  yichangdaiji: '异常待机',
  unitminday: '单位:分/天',
  unitci: '单位:次/天',
  daijihour: '待机时长',
  daijici: '待机次数',
  daijiReasonTijiao: '待机原因已提交（次）/ 待机原因未提交（次）',
  daijiReasonShenhe: '待机原因已审核（次）/ 待机原因未审核（次）',
  reasonTijiao: '原因已提交',
  reasonUnTijiao: '原因未提交',
  reasonShenhe: '原因已审核',
  reasonUnShenhe: '原因未审核',
  daijizhuangkuang: '待机状况',
  daijiReasonzhuangkuang: '待机原因提交状况',
  daijiReasonshenhe: '待机原因审核状况',
  yichangReasintongji: '异常待机主要原因统计',
  unci: '未填写的次数',
  hour: '和时长',
// '未填写的次数（）和时长（）',
  cihour: '已填写-次数/时长',
  ciliao: '材料包括',
  shujumiaoshutext: '印版、纸张、油墨、胶辊、橡皮布、润版液、喷粉、光油',
  yinshuaDevice: '印刷设备维保',
  daybaoyang: '当日保养',
  yinshuajiState: '印刷机状态',
  dianjianday: '点检日保',
  baoyangday: '保养日保',
  daybaoyangRate: '日保养完成率',
  deviceweibao: '印前设备维保',
  pqesReport: 'PQES报告上传',
  secai: '色彩管理',
  class: '班组',
  uploadci: '应上传次数',
  uploadcipoint: '实际上传次数/ 日平均得分',
  shijiuploadci: '实际上传次数',
  averageScore: '平均得分',
  weeks4: '4个周',
  zongyinshuanumber: '印厂总印刷数量',
  unitweeks: '单位:张/周',
  time: '时间',
  heji: '合计',
  weeksshijiyinliang: '周实际印量',
  weekSaverageliyonglv: '周平均产能利用率',
  jitainumber: '单机台(4周)印刷数量',
  xuanzeDevice: '请选择设备',
  weeksyinliang: '周印量',
  zongxingnengjiadonglv: '印厂总性能稼动率',
  weeksAveragesudu: '周平均运行速度',
  weeksAveragejiadonglv: '周平均性能稼动率',
  jidaiweeks4jiadonglv: '单机台(4周汇总)平均性能稼动率',
  edingsudu: '额定速度',
  weeks4sudu: '4周平均运行速度',
  weeksxingnengjiadonglv: '4周平均性能稼动率',
  jitaiweeksjiadonglv: '单机台(4周)平均性能稼动率',
  yunxingsudu: '运行速度',
  yinchangjiadonglv: '印厂总时间稼动率',
  unithourweeks: '单位:小时/周',
  weeksyouxiaoyinshua: '周有效印刷时长(小时)',
  weeksAverageTime: '周平均时间稼动率',
  jitaiAverageTimes: '单机台（4周汇总）平均时间稼动率',
  weeks4yinshua: '4周有效印刷时长(小时)',
  weeksshijianjiadonglv: '4周时间稼动率',
  jitaiAverageTime: '单机台（4周）平均时间稼动率',
  yichangdaijidata: '异常待机(数据)',
  daijihuizong: '印厂待机情况汇总',
  daijihourReason: '待机时长/ 待机原因已提交 / 待机原因未提交',
  yichangdaijizhuangkuang: '异常待机状况',
  jitaidaijidata: '单机台（4周汇总）异常待机数据',
  jitaiweeksdata: '单机台（4周）每周待机数据',
  daijiReason: '异常待机(原因)',
  daijiReasonhuizong: '待机原因汇总',
  daijicishuReason: '4周待机次数主要原因统计',
  zongcishu: '总次数',
  weitianxie: '未填写',
  duo: '多',
  // '第--多',
  Proportion1: '1-3占比',
  Proportion2: '4-6占比',
  weekshourReason: '4周待机时间主要原因统计',
  zonghour: '总时长',
  yinshuajidaijiReason: '单印刷机待机原因',
  daijiTimecishu: '待机时间原因-次数',
  reasoncishu: '该6项原因占总次数的--%',
  daijiTimeReason: '待机时间原因-时间',
  reasonTime: '该6项原因占总时间--%',
  baoyangweeks: '保养周保',
  dianjianweeks: '点检周保',
  zhoubaowanchenglv: '周保养完成率',
  yinggaiupload: '应该上传次数',
  shijishangchuan: '实际上传次数',
  months4: '4个月',
  unitMonths: '单位:张/月',
  monthsshijiyinliang: '月实际印量',
  monthsAverageliyonglv: '月平均产能利用率',
  jitaiyinshunumber: '单机台(4个月)印刷数量',
  monthsyinliang: '月印量',
  monthsAveragesudu: '月平均运行速度',
  monthsAveragejiadonglv: '月平均性能稼动率',
  months4Averagejiadonglv: '单机台(4个月汇总)平均性能稼动率',
  months4Averagesudu: '4个月平均运行速度',
  months4Averagexnjdl: '4个月平均性能稼动率',
  jtmonths4pjxnjdl: '单机台(4个月)平均性能稼动率',
  monthsyouxiaohour: '月有效印刷时长(小时)',
  monthsAveragetime: '月平均时间稼动率',
  jitaimonths4Averagetime: '单机台（4个月汇总）平均时间稼动率',
  unitmonths: '单位:小时/月',
  months4yxysHour: '4个月有效印刷时长(小时)',
  months4AverageTime: '4个月平均时间稼动率',
  jitaimonths4AverageTime: '单机台（4个月）平均时间稼动率',
  jitaimonths4daijidata: '单机台（4个月汇总）异常待机数据',
  cishuci: '次数(次)',
  hourTime: '时长(小时)',
  jitaimonths4data: '单机台（4个月）每月待机数据',
  months4cishuReason: '4个月待机次数主要原因统计',
  months4timeReason: '4个月待机时间主要原因统计',
  banyuebao: '半月保',
  baoyangbanyuebao: '保养半月保',
  dianjianbanyuebao: '点检半月保',
  banyuebaoyangwanchenglv: '半月保养完成率',
  months: '月保',
  baoyangmonths: '保养月保',
  dianjianmonths: '点检月保',
  monthsbaoyanglv: '月保养完成率',
  months4sjyxsd: '4个月实际运行速度',
  Averagejiadonglv: '平均性能稼动率',
  djReasonytjsc: '待机原因已提交时长',
  djReasonwytjsc: '待机原因未提交时长',
  ycdjcs: '异常待机次数',
  djReasontjcs: '待机原因已提交次数',
  djReasonwtjcs: '待机原因未提交次数',
  xuanzefourday: '必须选择四个日期',
  submitcishu: '已提交次数',
  submitHour: '已提交时长',
  submitted: '已提交',
  notSubmit: '未提交',
  reviewed: '已审核',
  unreviewed: '未审核',
  score: '得分',
  reportDay: '数据报告(日报)',
  reportWeeks: '数据报告(周报)',
  reportmonths: '数据报告(月报)',
  yszljc: '印刷质量监测',
  yszlreport: '印刷质量报表',
  zhiliangshuju: '质量数据',
  zongtizhiliangqushi: '总体质量趋势',
  months_total: '本月内设备上传的印刷质量数据的趋势统计',
  weeks_total: '本周内设备上传的印刷质量数据的趋势统计',
  score_contrast: '评测分数对比',
  months_overview: '对比分析本月与上月印刷质量概况',
  weeks_overview: '对比分析本周与上周印刷质量概况',
  upload_number: '上传次数',
  huanbi: '环比',
  score_duibi: '分数占比对比',
  months_zhanbi: '对比本月与上月印刷质量分数各个分数段的占比情况',
  weeks_zhanbi: '对比本周与上周印刷质量分数各个分数段的占比情况',
  all_yinshuaji: '全部印刷机',
  max_score: '最高分',
  min_score: '最低分',
  average_score: '平均分',
  quality_report: '制版质量报告',
  submit_time: '提报时间',
  bancai: '版材',
  all: '全部',
  ribao: '日保',
  zhoubao: '周保',
  nianbao: '年保',
  ranking: '排名',
  day_ranking: '按照昨日日保的点检评分进行排名',
  weeks_ranking: '按照上周周保的点检评分进行排名',
  months_ranking: '按照上月月保的点检评分进行排名',
  no_ranking_information: '暂无排名信息',
  maintenance_quality_score: '保养质量评分统计',
  device30_score: '每台设备最近三十次日保点检评分统计',
  device_weeks_score: '每台设备最近四周周保点检评分统计',
  device_months_score: '每台设备最近六个月月保点检评分统计',
  dianjian_score_ranking: '按照上一次半年保的点检评分进行排名',
  half_year_ranking: '半年保排名',
  year_ranking: '年保排名',
  last_year_ranking: '按照去年年保的点检评分进行排名',
  baoyangren: '保养人',
  point: '分',
  maintenance_report: '维修报表',
  maintenance_total: '维修总数',
  in_maintenance: '内部维修',
  out_maintenance: '外部维修',
  maintenance_cost: '维修费用',
  maintenance_problem: '维修问题汇总',
  months_maintenance: '本月维修记录',
  maintenance_person: '维修人',
  in: '内部',
  out: '外部',
  work_hours: '工时(h)',
  cost: '费用',
  maintenance_program: '维修方案',
  finish_time: '完成时间',
  course: '课程',
  my: '我的',
  charge_person: '负责人',
  serve_normal: '设备正常',
  water_yichang: '水箱异常',
  maintainance: '保养',
  quality_abnormal: '质量异常',
  yinqian_device: '印前设备',
  yinshua_device: '印刷设备',
  yinhou_device: '印后设备',
  device_info: '设备信息',
  jishenbianhao: '机身编号',
  brand_model: '品牌型号',
  device_type: '设备类型',
  class_info: '班组信息',
  inspector: '点检人',
  color_quality_record: '最近一条色彩质量记录',
  fraction: '分数',
  reporter: '提报人',
  maintenance_toDo: '保养待办',
  check_all: '查看所有',
  maintainance_start_time: '保养开始时间',
  maintainance_end_time: '保养结束时间',
  day_maintainance: '日保养',
  weeks_maintainance: '周保养',
  half_months_maintainance: '半月保养',
  months_maintainance: '月保养',
  quarter_maintainance: '季度保养',
  half_year_maintainance: '半年保养',
  year_maintainance: '年保养',
  go_maintenance: '去保养',
  abnormal_daiban: '异常待机待办',
  go_report: '去提报',
  water_tank_daiban: '水箱报警待办',
  alarm_type: '报警类型',
  alarm_time: '报警时间',
  range_value: '范围值',
  alarm_value: '报警值',
  qutianbao: '去填报',
  inspection_daiban: '点检代办',
  maintenance_time: '保养时间',
  device_data: '设备数据',
  wd: '温度',
  wandibgxishu: '稳定系数',
  selete_time: '选择时间',
  last_months: '上月',
  last_week: '上周',
  day: '本日',
  week: '本周',
  month: '本月',
  technical_support: '技术支持',
  my_share: '我的分享',
  set: '设置',
  selete_your_role: '请选择您的角色',
  selete_role: '请选择角色',
  cancel: '取消',
  info_center: '消息中心',
  unread: '未读',
  no_more: '没有更多了',
  gaojing: '告警',
  weixiu: '维修',
  keywords: '请输入关键字',
  category_name: '请选择分类名称',
  updated: '最近更新',
  personal_info: '个人信息',
  change_password: '修改密码',
  guanyi_my: '关于我们',
  feedback: '意见反馈',
  deregister_account: '注销账号',
  log_out: '退出登录',
  account_log_out: '账号即将永久注销',
  account_log_out_text: '您确认提交账号注销申请后，当前账号即将被退出。并且您的账号将在14天后被注销，若中途期望取消注销账号。可以重新登录以取消注销申请。',
  cancel_log_out: '取消注销',
  my_company: '我的公司',
  nick_name: '昵称',
  content: '内容',
  revise_name:'修改昵称',
  enter_name:'请输入昵称',
  gender:'性别',
  selete_gender:'选择性别',
  // cell_phone:'手机',
  email:'邮箱',
  revise_email:'修改邮箱',
  enter_email:'请输入邮箱',
  title:'标题',
  baomi:'保密',
  men:'男',
  women:'女',
  revise_success:'修改成功',
  revise_error:'修改失败',
  name_empty:'名称不能为空',
  email_empty:'邮箱不能为空',
  original_password:'原密码',
  enter_password:'请输入原密码',
  new_password:'新密码',
  enter_new_password:'请输入新密码',
  confirm_password:'确认密码',
  again_enter_password:'请再次输入密码',
  password_yanzheng:'密码为6-16位字母、数字或符号',
  tianxiewanzheng:'请填写完整',
  password_length:'密码长度为6-16',
  twice_password:'两次输入密码不一致',
  password_error:'原密码错误',
  feedback_text:'请输入您要反馈的问题(5-500字以内)',
  question_img:'请提供问题的截图或者照片(选填)',
  add_img:'添加照片',
  feedback_hint:'您的反馈我们会尽快解决，但无法保证每一条都能及时反馈，如果有紧急咨询，请直接拨打客服电话',
  submit:'提交',
  most_uploaded_img:'最多上传3张照片',
  uploaded_beyond:'上传的图片大于20M,请重新选择',
  submit_success:'提交成功',
  printing_cybertech:'印刷管家',
  printing_uer_protocol:'印工社用户协议',
  privacy_policy_statement:'隐私政策声明',
  check_renew:'检查更新',
  printing_copyright:'印工社 版权所有',
  version:'当前已是最新版本号',
  privacy_policy:'隐私政策',
  ToDo_list:'待办事项',
  inspection:'点检',
  regular_replacement:'定期更换',
  assigned:'待指派',
  planned_date:'计划日期',
  completion_date:'实际完成日期',
  maintenance_type:'保养类型',
  fault_issue:'故障问题',
  replacement_item:'更换项目',
  duration:'持续时间',
  maintenance_record:'保养记录',
  inspection_record:'点检记录',
  replacement_record:'更换记录',
  repair_record:'维修记录',
  alarm_record:'告警记录',
  inspection_sheet:'点检单',
  sheet_number:'单号',
  maintenance_details:'保养详情',
  site_details:'点位详情',
  lubrication:'润滑',
  inspection_cleaning:'检查、清洁',
  adjustment:'调节',
  switch:'切换',
  number:'编号',
  completion_status:'完成情况',
  inspection_success:'点检成功',
  inspection_failure:'点检失败',
  switching_success:'切换成功',
  maintenance_item_detail:'保养项目详情',
  remark:'备注',
  photo_upload:'拍照上传',
  regular_replacement_approval:'定期更换审批',
  submitter:'提报人员',
  rejected:'驳回',
  approved:'通过',
  equipment_repair_assignment:'设备维修指派',
  basic_data:'基本数据',
  not_started:'未开始',
  pending_start:'待开始',
  pending_confirmation:'待确认',
  completed:'已完成',
  in_progress:'进行中',
  repair_request_number:'报修单号',
  equipment_model:'设备型号',
  repair_request_time:'报修时间',
  repair_request_personnel:'报修人员',
  repair_personnel:'维修人员',
  detailed_information:'详细信息',
  fault_type:'故障类型',
  fault_description:'故障描述',
  assign:'指派',
  reassign:'改派',
  not_maintained:'未保养',
  maintenance_completed:'保养完成',
  inspection_completed:'点检完成',
  cancelling:'取消中',
  cancelled:'已取消',
  overtime_cancellation:'超时取消',
  inspection_overtime_cancellation:'点检超时取消',
  incomplete:'未完成',
  maintenance_information:'保养信息',
  maintenance_sheet_number:'保养单号',
  estimated_time:'预计时间',
  // submitter:'提交人',
  submission_reason:'提报原因',
  start:'开始',
  maintenance_items:'保养项目',
  upload_img:'最多可上传9张图片',
  enter_cancel_reason:'请输入取消原因',
  device_maintenance_mismatch:'二维码设备和保养设备不一致',
  operation_successful:'操作成功',
  maintenance_items_submitting:'请完成所有保养项目再提交',
  position:'部位',
  component:'组件',
  work:'工作',
  maintenance_standard:'保养标准',
  inspection_time:'点检时间',
  all_maintenance:'全部保养',
  basic_information:'基本信息',
  submission_sheet_number:'提报单号',
  approval_information:'审批信息',
  approval_result:'审批结果',
  approver:'审批人',
  approval_time:'审批时间',
  fault_image:'故障图片',
  no_image:'暂无图片',
  fault_video:'故障视频',
  click_play:'点击播放',
  repair_information:'维修信息',
  work_report:'工作结束，填写报告',
  confirm_completion:'确认完成',
  start_work:'开始工作',
  video_available:'暂无视频',
  acceptance_results:'验收结果',
  please_enter_acceptance_results:'请输入验收结果',
  order_completed:'订单已完成',
  personnel_selection:'人员选择',
  contact_information_available:'暂无联系方式',
  // alarm_type:'告警类型',
  // alarm_time:'告警时间',
  alarm_details:'告警详情',
  alarm_information:'告警信息',
  alarm_reason:'告警原因',
  // range_value:'范围值',
  // alarm_value:'告警值',
  solution:'解决方案',
  maintenance_date:'保养日期',
  Plate_making_submission:'制版提报',
  plate_making_quality_monitoring:'制版质量监测',
  check_cleaning:'检查清洁',
  add:'添加',
  add_report:'添加报告',
  CTP_machine:'制板机',
  please_select_CTP_machine:'请选择制版机',
  plate_material:'请选择版材',
  CTP_machine_name:'制板机名称',
  plate_material_name:'版材名称',
  please_fill_25:'请填写25%/30%',
  enter_plate_material_name:'请输入版材名称',
  confirm_delete_current_plate_material:'确定删除当前版材吗',
  delete_plate_material:'删除版材',
  maintenance_approval:'保养单审批',
  cancel_maintenance_reason:'取消保养原因',
  exception_submission:'异常提报',
  ink_precision:'放墨精度',
  ink_status:'油墨状态',
  quality_monitoring:'质量监测',
  exception_submission_approval_record:'异常提报审批记录',
  working_hours:'工作时长',
  approval_opinion:'审批意见',
  enter_approval_submitting:'请输入审批意见后再提交',
  latest_score:'最新评分',
  none:'暂无',
  report_details:'报告详情',
  historical_processing_records:'历史处理记录',
  ink_precision_report:'放墨精度报告',
  ink_precision_printing_machine_week:'查看每台印刷机近一周CMYK四色放墨精度误差的趋势数据',
  ink_precision_printing_machine_month:'查看每台印刷机近一月CMYK四色放墨精度误差的趋势数据',
  last_month:'近一月',
  // last_week:'近一周',
  average_deviation_trend:'平均偏差趋势',
  Average_deviation:'平均差值',
  over_standard_ink:'超标油墨',
  details:'详情',
  over_sstandard_status:'超标状态',
  over_standard_order:'超标订单',
  processing_time:'处理时间',
  processing_completed:'处理完成',
  maintenance_personnel:'保养人员',
  create_submission:'创建提报',
  work_plan:'工作计划',
  equipment_repair:'设备报修',
  water_tank_alarm:'水箱告警',
  please_select_material_reason:'请选择材料原因',
  Please_fill_remarks:'请填写备注',
  select_start_time:'请选择开始时间',
  select_end_time:'请选择结束时间',
  enter_working_hours:'请输入工作时长',
  select_equipment_name:'请选择设备名称',
  enter_rated_standby_time:'请输入额定待机时长',
  enter_abnormal_standby_time:'请输入异常待机时长',
  start_time_greater_end_time:'开始时间不能大于结束时间',
  please_select_submission_reason:'请选择提报原因',
  submission_successful:'提报成功',
  fault_repair:'故障报修',
  historical_repair_records:'历史维修记录',
  please_enter_fault_issue:'请输入故障问题(最多150字)',
  uploaded_image_large50M_please_reselect:'上传的图片大于50M,请重新选择',
  please_select_fault_type:'请选择故障类型',
  please_fill_fault_issue:'请填写故障问题',
  submit_exception:'提交异常',
  please_select_replacement_project:'请选择更换项目',
  work_start_time:'工作开始时间',
  please_select_work_start_time:'请选择工作开始时间',
  please_select_work_plan:'请选择工作计划',
  production:'生产',
  shutdown_maintenance:'停机保养',
  new_product:'打样(新品)',
  return_order:'打样(返单)',
  sample_chasing:'打样(追样)',
  no_task:'停机(无任务)',
  please_complete_information:'请把所有信息填写完整',
  update_time:'更新时间',
  creator:'创建人',
  length_characters:'长度在 2 到 20 个字符',
  order_number:'订单号',
  precision_erro:'精度误差%',
  total_score:'总分(%)',
  paper:'纸张',
  device_warning_submission:'设备警告填报',
  submit_report:'提交填报',
  please_select_alarm_reason:'请选择告警原因',
  please_select_solution:'请选择解决方案',
  my_courses:'我的课程',
  featured_courses:'精选课程',
  free:'免费',
  service_agreement_privacy_policy:'服务协议和隐私政策',
  service_agreement_privacy_policy_text:'请您务必审慎阅读、充分理解“服务协议“和“隐私政策”各条款，包括但不限于：为了更好的向您提供服务，我们需要收集您的操作日志等信息用于分析、优化应用性能',
  service_greement:'您可阅读《服务协议》和《隐私政策》了解详细信息。如果您同意，请点击下面按钮开始接受我们的服务',
  disagree:'不同意',
  agree:'同意',
  introduction:'简介',
  contents:'目录',
  course_introduction:'课程介绍',
  enroll_now:'立即报名',
  purchase_now:'立即购买',
  start_learning:'去学习',
  confirm_enrollment_now:'确认现在报名吗',
  enrollment_successful:'报名成功',
  course_price:'课程金额',
  purchase_course:'购买课程',
  actual_payment_amount:'实付金额',
  proceed_payment:'去付款',
  alipay:'支付宝',
  weChat:'微信',
  weChat_pay:'微信支付',
  course_payment:'课程支付',
  assignments:'作业',
  // reviewed:'已点评',
  pending_completion:'待完成',
  assignment_submission:'作业提交',
  file_upload:'文件上传',
  login:'登录',
  register:'注册',
  phone:'手机',
  password:'密码',
  please_enter_verification_code:'请输入验证码',
  read_agree_registration_agreement_privacy_policy:'阅读并同意《注册协议》和《隐私政策》',
  forgot_password:'忘记密码',
  welcome_printer_cybertech:'欢迎登录印刷管家',
  please_enter_phone_number:'请输入手机号',
  please_enter_valid_phone_number:'请输入正确的手机号',
  please_enter_password:'请输入密码',
  please_enter_account_nickname:'请输入账号昵称',
  get_verification_code:'获取验证码',
  please_enter_nickname:'请输入昵称',
  please_enter_confirmation_password:'请输入确认密码',
  read_agree_registration_agreement:'阅读并同意《注册协议》',
  please_check_registration_agreement:'请勾选注册协议',
  password_confirmation_password_match:'密码和确认密码不一致',
  retrieve_again:'重新获取',

  verification_code_login:'验证码登录',
  phone_number:'手机号',
  account_password_login:'账号密码登录',
  account_registration:'账号注册',
  login_test:'登录即表示您已同意本平台服务协议和隐私政策',
  correct_verification_code_format:'请输入正确验证码格式',
  verification_code:'请输入验证码',
  mobile_number_cannot_empty:'手机号不能为空',
  already_have_account:'已有账号',
  Login:'去登录',
  repeat_password:'重复密码',
  numbers_letters_symbols:'6-16个字符，可包含数字、字母及符号',
  agree_service_agreement_privacy_policy:'注册即表示您已同意本平台服务协议和隐私政策',
  please_check_agree_agreement:'请勾选并同意协议',
  retrieve_password:'找回密码',
  return_login:'返回登录',
  password_reset_successful:'重置密码成功',
  big_screen_display_boss:'大屏展示老板',
  big_screen_display_workshop:'大屏展示车间',
  total_employees:'员工总数',
  total_number_data_acquisition_devices:'数采设备总数',
  ming:'名',
  tower:'台',
  total_number_devices:'设备总数',
  yesterday_quantity:'昨日印量',
  yesterday_time_utilization_Rate:'昨日时间稼动率',
  yesterday_performance_utilization_rate:'昨日性能稼动率',
  big_screen_display_machine:'大屏展示机台',
  printing_manager_data_operation_big_screen:'印刷管家数据运营大屏',
  printing_machine_time_utilization_rate:'印刷机时间稼动率',
  trend_chart:'近7日趋势图',
  compared_last_week:'相比上周',
  monthly_time_utilization_rate:'月时间稼动率',
  compared_last_month:'相比上月',
  printing_machine_performance_utilization_rate:'印刷机性能稼动率',
  weekly_performance_utilization_rate:'周性能稼动率',
  monthly_performance_utilization_rate:'月性能稼动率',
  color_quality:'色彩质量',
  score_month:'本月得分',
  increase_from:'提升到',
  increase_of: '提升',
  equals_printing:'相当于',
  more_sheets_per_day:'每天多印',
  decrease_from:'下降到',
  decrease_of:'下降',
  fewer_sheets_per_day:'每天少印',
  printing_sheets_increase:'0%提升到2.45%，提升2.45%相当于每天多印12250张',
  printing_sheets_decrease:'0%下降到2.45%，下降2.45%相当于每天少印12250张',
  last_month_score:'上月得分',
  this_week_score:'本周得分',
  last_week_score:'上周得分',
  project_entry:'项目入场',
  color_quality_trend:'色彩质量趋势',
  equipment_status:'设备状态(水墨平衡)',
  system_messages:'系统消息',
  view_user_profile:'查看用户资料',
  edit_user_profile:'编辑用户资料',
  please_enter_old_password:'请输入旧密码',
  characters:'请输入6-16个非空格字符',
  weeks:'星期日, 星期一, 星期二, 星期三, 星期四, 星期五, 星期六',
  log_out_user:'确定要退出此用户',
  view_profile:'查看资料',
  edit_profile:'编辑资料',
  edit_mobile_number:'编辑手机号',
  edit_password:'编辑密码',
  space_usage:'空间使用',
  account_nickname:'账号呢称',
  contact_phone:'联系电话',
  contact_email:'联系邮箱',
  company_name:'企业名称',
  upload_avatar:'上传头像',
  enter_account_nickname:'请输入账号昵称',
  original_phone_number:'原手机号',
  resend_in:'重新发送',
  seconds: '秒',
  resend_seconds:'秒后重新发送',
  new_phone:'新手机号',
  uploaded_image:'上传失败！上传图片只能是JPG和JPEG格式',
  uploaded_file_size_exceed:'上传失败！上传文件大小超过5MB',
  verification_code_cannot:'验证码不能为空',
  verification_code_format:'验证码格式错误',
  phone_verification_code:'手机或验证码不能为空',
  incorrect_phone_number_format:'手机号格式错误',
  phone_again:'手机号已更换，请重新登录',
  prompt:'提示',
  invalid_email:'邮箱不合法',
  password_edited_success:'编辑密码成功',
  password_changed_again:'密码已更换，请重新登录',
  printing_quality:'印刷质量',
  balance_report:'水墨平衡测试报告',
  evaluation_time:'测评时间',
  printing_maintenance_completion_status:'印刷保养完成情况',
  maintenance_completion_rate:'保养完成率',
  inspection_completion_rate:'点检完成率',
  weekly_maintenance:'双周保',
  quarterly_maintenance:'季度保',
  semi_annual:'半年度',
  annual:'年度',
  abnormal_standby_reporting:'异常待机提报',
  trigger_count:'触发次数',
  report_submission_count:'填报次数',
  omission_count:'遗漏次数',
  water_alarm:'水箱报警',
  water_tank_data:'水箱数据',
  abnormal_trigger:'异常触发',
  report_completion_rate:'提报完成率',
  water_tank_monitoring:'水箱监控',
  member:'成员',
  today:'当日',
  maintenance_work_status:'保养工作情况',
  uncompleted_maintenance_orders:'未完成保养单',
  ongoing_maintenance_order_status:'进行中保养单情况',
  uncompleted_inspection_points:'未完成点位',
  inspection_points:'总点位',
  uncompleted_maintenance:'未完成保养清单',
  latest_evaluation:'最近一次评测',
  repair_work_status:'维修工作情况',
  no_data_unbound_devices:'未绑定设备，无数据',
  damping_solution:'润版液',
  maintenance_inspection:'保养&点检',
  printing_quantity_analysis:'印量分析',
  view_details:'查看详情',
  select_start_date:'选择开始日期',
  select_date:'选择日期',
  select_end_date:'选择结束日期',
  machine:'机台',
  shift:'班次',
  acquisition_device:'请选择数采设备',
  please_select_team:'请选择班组',
  please_select_device_type:'请选择设备类型',
  export:'导出',
  query:'查询',
  enterprise_leader:'企业负责人',
  production_manager:'生产主管',
  equipment_supervisor:'设备主管',
  prepress_supervisor:'印前主管',
  printing_supervisor:'印刷主管',
  printing_staff:'印刷职员',
  manager:'人事主管',
  total_statistics:'统计全部',
  view_large_image:'查看大图',
  rated_changeover_time:'额定换型时间',
  excess_changeover_time:'换型超出时间',
  changeover_exception_cause:'换型异常原因',
  date:'日期',
  actual_changeover_hours:'实际换型工时',
  effective_work_duration:'有效工作时长',
  please_select_start_end_time:'请选择开始时间或结束时间',
  exception_standby_details:'异常待机明细',
  return:'返回',
  quality_score:'质量评分',
  upload_quantity:'上传数量',
  select_companies:'最多只能选择5个公司',
  alert_start_time:'告警开始时间',
  alert_end_time:'告警结束时间',
  out_range_value:'超范围值',
  handler:'处理人',
  reason:'原因',
  damping_solution_details:'润版液明细',
  maintenance_inspection_achievement_rate:'保养&点检达成率',
  score_ranking:'得分排名',
  working_hours_min:'工时(分钟)',
  maintenance_cost_yuan:'维修费用(元)',
  trend_plate_quality:'制版质量50%的点的趋势',
  effective_duration_minutes:'有效时长(分钟)',
  overrun_duration_minutes:'过版时长(分钟)',
  ineffective_duration_minutes:'无效时长(分钟)',
  periodic_replacement:'定期更换列表',
  all_documents:'全部文档',
  all_categories:'全部分类',
  enter_keywords:'请输入关键词',
  team_management:'团队管理',
  personnel_management:'人员管理',
  team_information:'团队信息',
  shift_management:'班组管理',
  meal_management:'用餐管理',
  external_maintenance:'外部维修工管理',
  equipment_maintenance:'设备运维',
  submission_record:'提报记录',
  nickname_phone:'昵称/手机号',
  account_role:'账号角色',
  respective_shift:'所在班组',
  search:'搜索',
  registration_time:'注册时间',
  edit_account:'编辑账号',
  day_shift:'白班',
  night_shift:'晚班',
  invalid_phone:'手机号不合法',
  confirm_delete_current_account:'确定删除当前账号吗',
  delete:'删除',
  enterprise_address:'企业地址',
  province:'省',
  city:'市',
  district:'区',
  work_cycle:'工作周期',
  yesterday:'昨日',
  detailed_address:'详细地址',
  enterprise_email:'企业邮箱',
  enterprise_introduction:'企业介绍',
  upload_logo:'上传Logo',
  click_upload:'将Logo拖到此处，或  点击上传',
  allowed_should:'只能上传jpg/png图片文件，且不超过500kb',
  confirm_upload:'确认上传',
  company_name_characters:'请输入2-50个字符的企业名称',
  detailed_address_characters:'详细地址不超过50字',
  company_introduction_should:'公司介绍不超过500字',
  file_name_incorrect:'文件名有误',
  file_cannot_exceed:'文件不能超过500kb',
  upload_file_original_file:'最多只能上传1个文件，请删除原文件后再重新上传',
  upload_company:'请上传企业Logo',
  upload_success:'上传成功',
  working_time:'工作时间(小时)',
  working_time_min:'非工作时间(分钟)',
  creation_time:'创建时间',
  start_time:'起始时间',
  please_enter_working_time:'请输入工作时间',
  non_working_time:'非工作时间',
  rest_time:'休息时间',
  add_team:'添加班组',
  length30_characters:'长度在 2 到 30 个字符',
  enter_team_name:'请输入班组名称',
  enter_rest_time:'请输入休息时间',
  positive_integer:'必须为正整数',
  delete_current_team:'确定删除当前班组吗',
  delete_team:'删除班组',
  edit_team:'编辑班组',
  non_working_working_time:'非工作时间不能大于工作时间',
  add_increase:'增加',
  length_within_characters:'长度在 20 个字符内',
  confirm_deletion_device:'确认删除设备, 是否继续',
  deletion_cancelled:'已取消删除',
  name_phone:'姓名/手机号',
  account_status:'账号状态',
  adding_time:'添加时间',
  last_login_time:'上次登录时间',
  please_enter_name:'请输入姓名',
  name:'姓名',
  enter_password_again:'请输入重复密码',
  enable:'启用',
  disable:'停用',
  add_repairman:'添加维修工',
  edit_repairman:'编辑维修工',
  edit_successful:'编辑成功',
  add_successful:'添加成功',
  enable_current_repairman_account:'确定启用当前维修工账号吗',
  enable_account:'启用账号',
  disable_current_repairman_account:'确定停用当前维修工账号吗',
  disable_account:'停用账号',
  search_reason:'搜索原因',
  reason_optional:'原因选填',
  type:'类型',
  platform:'平台',
  enterprise:'企业',
  is_it_enable:'是否启用',
  not_enabled:'不启用',
  length_within12_characters:'长度在 12 个字符内',
  select_status:'请选择状态',
  success_enabled:'启用成功',
  successfully_disabled:'关闭成功',
  confirm_deletion_reason:'确认删除原因, 是否继续',
  // device_list:'设备列表',
  device_details:'设备明细',
  device_code:'设备编码',
  enter_device_code:'请填写设备编码',
  enter_machine_serial_number:'请填写机身编号',
  machine_serial_number:'机身编号',
  select_brand_model:'请选择品牌型号',
  maintenance_strategy:'保养策略',
  semi_annual_maintenance:'半年度保养',
  annual_maintenance:'年度保养',
  time_range:'时间范围',
  daily:'每天',
  monthly:'每月',
  to:'到',
  // '1日到9日',
  first_quarter_february:'第一季度',
  // '第一季度2月12日到15日',
  second_quarter:'第二季度',
  third_quarter:'第三季度',
  fourth_quarter:'第四季度',
  first_half:'上半年',
  second_half:'下半年',
  strategy:'策略',
  select_maintenance_person:'请选择保养人',
  select_inspection_person:'请选择点检人',
  bi_weekly:'双周',
  device_qr_code:'设备二维码',
  download_qr_code:'下载二维码',
  device_configuration:'设备配置',
  non_data_acquisition_device:'非数采设备',
  data_acquisition_device:'数采设备',
  device_name_characters:'设备名称为4-32个字符，其中一个汉字占两个字符',
  enter_device_name:'请输入设备名称',
  please_enter_machine_serial_number:'请输入机身编号',
  not_exceed_characters:'机身编号不超过20个字',
  enter_rated_standby_time_min:'请输入额定待机时长(单位：分钟)',
  enter_brand_model_within_characters:'请填写品牌型号并不超过20个字',
  remarks_should_not_exceed_characters:'备注不超过100个字',
  monday:'每周一',
  tuesday:'每周二',
  wednesday:'每周三',
  thursday:'每周四',
  friday:'每周五',
  saturday:'每周六',
  sunday:'每周日',
  qr_code:'二维码',
  daily_maintenance_strategy_template:'请选择日保养策略模版',
  daily_maintenance_person:'请选择日保养保养人',
  daily_inspection_person:'请选择日保养点检人',
  printmaking_machine_name:'制版机名称',
  weekly_maintenance_strategy_template:'请选择周保养策略模版',
  weekly_maintenance_person:'请选择周保养保养人',
  weekly_inspection_person:'请选择周保养点检人',
  semi_monthly_maintenance_strategy_template:'请选择半月保养策略模版',
  semi_monthly_maintenance_person:'请选择半月保养保养人',
  semi_monthly_inspection_person:'请选择半月保养点检人',
  Semi_monthly_maintenance_range_selection:'半月保养时间范围选择错误，请确认',
  maintenance_strategy_template:'请选择月保养策略模版',
  monthly_maintenance_person:'请选择月保养保养人',
  monthly_inspection_person:'请选择月保养点检人',
  monthly_maintenance_range_selection:'月保养时间范围选择错误，请确认',
  quarterly_maintenance_strategy_template:'请选择季度保养策略模版',
  quarterly_maintenance_person:'请选择季度保养保养人',
  quarterly_inspection_person:'请选择季度保养点检人',
  quarterly_maintenance_first_quarter_time:'季度保养第一季度时间范围选择错误，请确认',
  quarterly_maintenance_second_quarter_time:'季度保养第二季度时间范围选择错误，请确认',
  quarterly_maintenance_third_quarter_time:'季度保养第三季度时间范围选择错误，请确认',
  quarterly_maintenance_fourth_quarter_time:'季度保养第四季度时间范围选择错误，请确认',
  semi_annual_maintenance_strategy_template:'请选择半年度保养策略模版',
  semi_annual_maintenance_person:'请选择半年度保养保养人',
  semi_annual_inspection_person:'请选择半年度保养点检人',
  semi_annual_maintenance_first_half_time:'半年度保养上半年时间范围选择错误，请确认',
  semi_annual_maintenance_second_half_time:'半年度保养下半年时间范围选择错误，请确认',
  annual_maintenance_strategy_template:'请选择年度保养策略模版',
  annual_maintenance_person:'请选择年度保养保养人',
  annual_inspection_person:'请选择年度保养点检人',
  annual_maintenance_time_range:'年度保养时间范围选择错误，请确认',
  other:'其它',
  template:'模板',
  template_list:'模板列表',
  template_type:'模板类型',
  printing_type:'印刷类型',
  personal_template:'个人模板',
  copy:'复制',
  select_file_upload:'请选择要上传的文件',
  download_import_template:'下载导入模板',
  system_template:'系统模板',
  enter_template_name_characters:'请输入1-30字的模板名称',
  enter_template_name:'请输入模板名称',
  confirm_deletion:'是否确认删除',
  prepress_automation:'印前自动化',
  prepress_project:'印前项目',
  enter_project_search:'请输入要搜索的项目',
  project_information:'项目信息',
  delete_project:'删除项目',
  original_file:'原稿文件',
  enter_file_name_search:'请输入要搜索的文件名',
  view_original:'查看原稿',
  download_original:'下载原稿',
  delete_original:'删除原稿',
  process_file:'处理文件',
  view_file:'查看文件',
  download_file:'下载文件',
  delete_file:'删除文件',
  selected_file:'已选文件',
  file_processing:'文件处理',
  history:'历史记录',
  original_file2:'原文件',
  preview:'预览',
  generate_file:'生成文件',
  parameter:'参数',
  layout_parameter:'版面参数',
  modify_parameter:'修改参数',
  create_project:'创建项目',
  enter_project_name:'请输入项目名称',
  project_name:'项目名称',
  project_type:'项目类型',
  select_project_type:'请选择项目类型',
  project_client:'项目客户',
  enter_project_client_name:'请输入项目客户名称',
  // project_information:'项目信息',
  project_number:'项目编号',
  tag:'标签',
  card_box:'卡盒',
  soft_pack:'软包',
  enter_select:'请输入或请选择',
  created_success:'创建项目成功',
  modified_succes:'修改项目成功',
  project_deletion:'确认删除项目, 是否继续',
  upload_failedpdf_cf2:'上传失败！上传文件只能是pdf格式和cf2格式',
  Upload_failed_file_size_exceeds:'上传失败！上传文件大小超过1GB',
  file_name_cannot_exceed_characters:'文件名过长，不能超过50字',
  select_project_first_before_uploading_file:'请先选择项目再上传文件',
  original_deletion:'确认删除原稿, 是否继续',
  feature_available:'此功能暂未开放',
  please_select:'请选择',
  files: '文件',
// '请选择2个文件',
  imposition:'拼版',
  select_one_pdf_file_one_cf2:'请选择一个pdf文件和一个cf2文件',
  only_pdf_files_selected:'只能选择pdf文件',
  please_select_file:'请选择文件',
  check_file_selected:'查看是否已选择文件',
  view_parameter:'查看参数',
  printer_cybertech:'印工社',

};

export default zh;
